<template>
  <b-modal
    id="user-edit-modal"
    ref="user-edit-modal"
    title="Edit"
    centered
    hide-footer
    no-close-on-backdrop
    @shown="doLoadData"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.stop.prevent>
        <b-row>
          <!-- !! First Name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="first name"
              rules="required"
            >
              <HeroInputText
                id="first-name"
                v-model="user.first_name"
                label="First Name"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Last Name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="last name"
              rules="required"
            >
              <HeroInputText
                id="last-name"
                v-model="user.last_name"
                label="Last Name"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Nickname -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="nick name"
              rules=""
            >
              <HeroInputText
                id="nickname"
                v-model="user.nickname"
                label="Nickname"
                column="4"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Email -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <HeroInputEmail
                id="email"
                v-model="user.email"
                label="Email"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! User Position -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="position"
              rules=""
            >
              <HeroVueSelect
                id="user-position-id"
                v-model="user.user_position_id"
                label="Position"
                column="4"
                :clearable="true"
                :options="$store.getters['UserPositionOptions/getOptions']"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
                @input="updateUserLevelOptions"
              />
            </validation-provider>
          </b-col>

          <!-- !! User Level -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="level"
              rules=""
            >
              <HeroVueSelect
                id="user-level"
                v-model="user.user_level"
                label="Level"
                column="4"
                :clearable="true"
                :disabled="!user.user_position_id"
                :options="$store.getters['UserLevelOptions/getOptions']"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Supervisor -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="supervisor"
              rules=""
            >
              <HeroVueSelect
                id="supervisor"
                v-model="user.supervisor_id"
                label="Supervisor"
                column="4"
                :options="$store.getters['SupervisorOptions/getOptions']"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Countries -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="countries"
              rules=""
            >
              <HeroVueSelectMultiple
                id="countries"
                v-model="user.countries"
                label="Countries"
                column="4"
                :options="$store.getters['CountryOptions/getOptions']"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Languages -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="languages"
              rules=""
            >
              <HeroVueSelectMultiple
                id="languages"
                v-model="user.languages"
                label="Languages"
                column="4"
                :required="false"
                :clearable="true"
                :options="$store.getters['LanguageOptions/getOptions']"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Roles -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="roles"
              rules=""
            >
              <HeroVueSelectMultiple
                id="roles"
                v-model="user.roles"
                label="Roles"
                column="4"
                :options="$store.getters['RoleOptions/getOptionsByName']"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Groups -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="groups"
              rules=""
            >
              <HeroVueSelectMultiple
                id="groups"
                v-model="user.groups"
                label="Groups"
                column="4"
                :options="$store.getters['GroupOptions/getOptions']"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Password -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="password"
              rules="password"
            >
              <HeroInputPassword
                id="password"
                v-model="user.password"
                label="Password"
                column="4"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction type="button" variant="primary" @click="validationForm">
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import AxiosBodyService from '@/services/AxiosBodyService'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroInputEmail from '@/views/components/form/inputs/HeroInputEmail.vue'
import HeroInputPassword from '@/views/components/form/inputs/HeroInputPassword.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroVueSelectMultiple from '@/views/components/form/selects/HeroVueSelectMultiple.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  email as emailRule,
  password as passwordRule,
  required,
} from '@validations'

export default {
  components: {
    HeroVueSelectMultiple,
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroInputText,
    HeroInputEmail,
    HeroInputPassword,
    HeroVueSelect,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    editId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: true,

      // Validation
      required,
      emailRule,
      passwordRule,

      original: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        nickname: '',
        user_position_id: '',
        user_level: '',
        supervisor_id: '',
        countries: [],
        languages: [],
        roles: [],
        groups: [],
      },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        nickname: '',
        user_position_id: '',
        user_level: '',
        supervisor_id: '',
        countries: [],
        languages: [],
        roles: [],
        groups: [],
      },
    }
  },
  methods: {
    async doCloseModal() {
      this.user = { ...this.original }
      await this.$refs['user-edit-modal'].hide()
    },

    async doLoadData() {
      this.showOverlay = true

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const response = await axios.get(`users/${this.editId}`, axiosConfig)
        const user = response.data.data

        this.user.first_name = user.first_name || ''
        this.user.last_name = user.last_name || ''
        this.user.email = user.email || ''
        this.user.nickname = user.nickname || ''
        this.user.user_position_id = user.user_position_id || ''
        this.user.user_level = user.user_level || ''
        this.user.supervisor_id = user.supervisor_id || ''
        this.user.countries = AxiosBodyService.getVueSelectMultipleFormatById(user.countries)
        this.user.languages = AxiosBodyService.getVueSelectMultipleFormatById(user.languages)
        this.user.roles = AxiosBodyService.getVueSelectMultipleFormatByName(user.roles)
        this.user.groups = AxiosBodyService.getVueSelectMultipleFormatById(user.groups)
      } catch (error) {
        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }

        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }

      this.showOverlay = false
    },

    async validationForm() {
      let alertResult

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        this.showOverlay = true

        // Update role data
        const roleBody = {
          role_names: this.user.roles,
        }

        try {
          await axios.post(`users/${this.editId}/syncRoles`, roleBody, axiosConfig)
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        // Update user data
        const body = {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          nickname: this.user.nickname,
          email: this.user.email,
          user_position_id: this.user.user_position_id,
          user_level: this.user.user_level,
          supervisor_id: this.user.supervisor_id,
          countries: this.user.countries,
          languages: this.user.languages,
          groups: this.user.groups,
        }

        if (this.user.password) {
          body.password = this.user.password
        }

        try {
          const response = await axios.patch(`users/${this.editId}`, body, axiosConfig)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            this.$store.commit('UserLevelOptions/setDoUpdate', true)
            this.$emit('success')
            this.user = { ...this.original }
            this.$refs['user-edit-modal'].hide()
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },

    async updateUserLevelOptions() {
      await this.$store.dispatch('UserLevelOptions/updateOptions', this.user.user_position_id)
      this.user.user_level = ''
    },
  },
}
</script>
