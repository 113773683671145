<template>
  <b-modal
    id="user-create-modal"
    ref="user-create-modal"
    title="Create"
    centered
    hide-footer
    no-close-on-backdrop
    @shown="doLoadData"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.stop.prevent>
        <b-row>
          <!-- !! First Name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="first name"
              rules="required"
            >
              <HeroInputText
                id="first-name"
                v-model="user.first_name"
                label="First Name"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Last Name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="last name"
              rules="required"
            >
              <HeroInputText
                id="last-name"
                v-model="user.last_name"
                label="Last Name"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Email -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <HeroInputText
                id="email"
                v-model="user.email"
                label="Email"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Password -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="password"
              rules="required|password"
            >
              <HeroInputPassword
                id="password"
                v-model="user.password"
                label="Password"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction type="button" variant="primary" @click="validationForm">
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroInputPassword from '@/views/components/form/inputs/HeroInputPassword.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  email as emailRule,
  password as passwordRule,
  required,
} from '@validations'

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroInputText,
    HeroInputPassword,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,
      passwordRule,
      emailRule,

      original: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
      },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
      },
    }
  },
  methods: {
    doCloseModal() {
      this.user = { ...this.original }
      this.$refs['user-create-modal'].hide()
    },

    async doLoadData() {
      this.showOverlay = false
    },

    async validationForm() {
      let alertResult

      const axiosConfig = AxiosConfig.getJsonWithoutToken()
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        this.showOverlay = true

        const body = this.user

        try {
          const response = await axios.post('register', body, axiosConfig)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            this.$store.commit('UserLevelOptions/setDoUpdate', true)
            this.$emit('success')
            this.user = { ...this.original }
            this.$refs['user-create-modal'].hide()
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },
  },
}
</script>
